import axios  from 'axios';
import authHeaders from './authHeaders';
const apiUrl = process.env.REACT_APP_API_URL || 'https://backend.allsealapp.ch/api';

function headers() {
  return {
    ...authHeaders(),
    'Content-Type': 'application/json',
  }
}

export function fetchProjects(offlinePlanIds) {
  return axios.get( apiUrl + `/projects/${JSON.stringify(offlinePlanIds)}`, {headers: headers()});
}

export function fetchWorks() {
  return axios.get( apiUrl + '/works', {headers: headers()});
};

export function fetchMyWorks(length) {
  let url = `${apiUrl}/myWorks`;
  if (length) {
    url += `?offset=${length}`;
  }
  return axios.get(url, {headers: headers()});
};
export function fetchWorksForPlan(planId) {
  return axios.get( `${apiUrl}/works/getByPlanId/${planId}`, {headers: headers()});
};

export function fetchWork(workId) {
  return axios.get( `${apiUrl}/works/${workId}`, {headers: headers()});
};


export function uploadWork(data) {
  // Delete project and plan because otherwise it gets assigned to the eloquent model
  const preparedData = { ...data };

  preparedData.temp_id = preparedData.key;
  delete preparedData.key;
  delete preparedData.project;
  delete preparedData.plan;
  delete preparedData.created_at;
  delete preparedData.uploaded_at;
  delete preparedData.uploading;

  return axios.post( apiUrl + '/works', preparedData, {headers: headers()});
}

export function isWorkCreated(data) {
  data.temp_id = data.key;
  //server return boolean value
  return axios.post(apiUrl + '/works/proof', data, {headers: headers()})
}

export function updateWork(data) {
  const preparedData = { ...data };

  preparedData.temp_id = preparedData.key;
  delete preparedData.key;
  delete preparedData.project;
  delete preparedData.plan;
  delete preparedData.updated_at;
  delete preparedData.uploaded_at;
  delete preparedData.uploading;

  return axios.put(`${apiUrl}/works/${data.id}`, {...preparedData}, {headers: headers()})
}


export function markAsSolved(data) {
  return axios.post( apiUrl + '/solved', data, {headers: headers()});
}

export function deleteWork(workId) {
  return axios.delete( apiUrl + '/works/'+workId, {headers: headers()});
}

export function login(credentials) {
  return axios.post( apiUrl + '/login', credentials, {headers: headers()});
}

export function me() {
  return axios.get( apiUrl + '/me', {headers: headers()});
}
