import React, { useState } from 'react';
import { Grid, Card, Icon, Button, Label, Modal } from 'semantic-ui-react'
import boolify from 'boolify'
import TimeAgo from 'react-timeago'
import germanTimeAgo from 'react-timeago/lib/language-strings/de'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import { getWorkTypeFields } from '../../services/workTypes';
import { Link } from "react-router-dom";
import FileBase64 from 'react-file-base64';
import { optimizeWorkImage } from '../../services/helpers';
import { store } from '../../services/fs';
import { history } from "../../store";
import {useSelector} from "react-redux";
import defaultImage from "../../assets/defaultImage";

const formatter = buildFormatter(germanTimeAgo)

export default ({work, deleteWork, uploadWork, editWork, markAsSolved, workIdTemp, pendingEditedWorks, user}) => {
  const [file, setFile] = useState(null);
  const { online } = useSelector(state => ({
    online: state.online
  }));
  const onDeleteClick = () => {
    window.confirm('Möchten Sie die Arbeit wirklich löschen?') && deleteWork(work.id);
  };

  const onEditClick = () => {
    let pushUrl = null;
    if (workIdTemp) {
      pushUrl = `/projekt/${work.plan.project_id}/plan/${work.plan.id}/edit/${workIdTemp}`
    } else {
      pushUrl = `/projekt/${work.plan.project_id}/plan/${work.plan.id}/edit/${work.id}`
    }

    history.push(pushUrl);
  };
  const isOwner = user.id === work.user_id;
  const image = work.solvedImage || work.publicImage || work.image;
  const time = work.created_at || work.created_at || null;
  const doneAt = work.done_at || null;
  const project = work.plan && work.plan.project ? work.plan.project : work.project;
  const plan = work.plan;
  const id = work.internal_id;
  const fields = getWorkTypeFields(work.type);
  const [editedUploading, editedUploadingWork] = getEditedUploading(pendingEditedWorks, work);

  const targetLabel = work.target === 'invoice'
    ? <div className="ui violet label">Rechnung</div>
    : <div className="ui yellow label">Angebot</div>;

  const done = work.done_at
    ? <div className="ui green label"><i aria-hidden="true" className="check icon"></i> Erledigt</div>
    : null;

  const onFileChanged = file => {
      optimizeWorkImage(
        file.base64,
        newDataUri => {
          // Generate a random identifier for the file
          const id = 'images.' + Math.random().toString(36).slice(-5);

          // Store the file to the localStorage
          // store(id, newDataUri).then( () => this.setState({imageId: id }));
          store(id, newDataUri).then( () => setFile({
            blob: newDataUri,
            id: id
          }) );
        }
      );
    };

  const resetFile = () => setFile(null);

  const previewFile = file => (
    <div>
      <img alt='work' src={file.blob} />
      <Button size='tiny' style={{marginTop: 10}} disabled={work.uploading} onClick={resetFile}>Zurücksetzen</Button>
    </div>
  );

  const fileUpload = file !== null
    ? previewFile(file)
    : <FileBase64 multiple={ false } onDone={ onFileChanged } />;

  const solved = () => {
    if (!file) {
      alert("Ein Bild ist erforderlich!");
    } else {
      markAsSolved(work, file !== null ? file.id : null);
    }
  };
  const handleError = (ev) => {
    ev.target.src = defaultImage;
  };
  const [modalState, setModalState] = useState(false);
  return (
      <Card fluid>
        {!work.imageId && image &&
        <div>
          <div className="work-widget-image">
            <img onError={handleError} onClick={() => setModalState(true)} className="work-widget-image" src={image} alt=""/>
          </div>
          <Modal open={modalState} size='large'>
            <Modal.Content className="work-widget-image-content">
              <img alt='work' src={image} />
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => setModalState(false)} negative>
                Schließen
              </Button>
            </Modal.Actions>
          </Modal>
        </div>}
        {work.imageId &&
        <div className="work-widget-image-no-internet">
          <Icon name='image' style={{
            fontSize: '40px',
            marginBottom: '20px'
          }}/>
          <div className="work-widget-image-content">Dieses Bild ist nach erfolgreichem Upload sichtbar.</div>
        </div>
        }
        <Card.Content>
          {((uploadWork && !work.uploading) || (editedUploadingWork && !editedUploading)) &&
          <Label className="small bottom margin" color='grey'>
            <Icon loading name='spinner' />
            Wartet auf Upload...
          </Label>
          }
          {(work.uploading || editedUploading) &&
          <Label className="small bottom margin" color='green'>
            <Icon loading name='spinner' />
            Wird hochgeladen...
          </Label>
          }
          <Card.Header>{work.type} {id && <span className="entry-id">#{id}</span>} {targetLabel} {done}</Card.Header>
          <Card.Meta>
            <Link to={"/projekt/"+project.id+"/plan/"+plan.id}>{plan.name}</Link><br />
            <Link to={"/projekt/"+project.id}>{project.name} <span className="entry-id">#{project.id}</span></Link>
          </Card.Meta>
        </Card.Content>
        <Card.Content style={{paddingTop: "1.4rem", paddingBottom: "1.4rem"}}>
          <Grid>
            {fields.map( field => {
              if(work.hasOwnProperty(field.name)) {
                return (
                  <Grid.Row style={{padding: "0.2rem 0"}} columns={2} key={"work-value-row-"+field.name}>
                    <Grid.Column><strong>{field.label}</strong></Grid.Column>
                    <Grid.Column>{work[field.name]} { field.unit ? field.unit : ''}</Grid.Column>
                  </Grid.Row>
                );
              } else return null;
            })}
          </Grid>
        </Card.Content>
        {work.note &&
        <Card.Content>
          <Card.Description>
            {work.note}
          </Card.Description>
        </Card.Content>
        }
        { ( !!boolify(work.additional_position) || !!boolify(work.non_confirm_installation) || !!boolify(work.wisa)) &&
        <Card.Content>
          { !!boolify(work.additional_position) && <div><Icon name='check square' /> Zusätzliche Position</div> }
          { !!boolify(work.non_confirm_installation) && <div><Icon name='check square' /> Kein konformer Einbau möglich</div>}
          { !!boolify(work.wisa) && <div><Icon name='check square' /> WISA</div>}
        </Card.Content>
        }
        {(markAsSolved && work.done_at === null) &&
        <Card.Content>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                {fileUpload}
              </Grid.Column>
              <Grid.Column>
                <Button disabled={work.uploading} size='small' icon labelPosition='left' color='green' fluid onClick={ solved } >
                  <Icon name='check' />
                  Als erledigt markieren
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
        }
        {isOwner && (deleteWork || editWork) &&
        <Card.Content>
          {editWork &&
          <Button size='small' style={{marginTop: 10}} icon labelPosition='left' fluid onClick={ onEditClick } >
            <Icon name='edit outline' />
            Eintrag bearbeiten
          </Button>
          }
          {deleteWork &&
          <Button size='small' style={{marginTop: 10}} icon labelPosition='left' fluid onClick={ onDeleteClick } >
            <Icon name='trash alternate outline' />
            Eintrag löschen
          </Button>
          }
        </Card.Content>
        }
        {(time || doneAt) &&
        <Card.Content extra>
          {work.user &&
          <div style={{display: 'inline-block', marginRight: 15}}>
            <Icon name='user' />
            {work.user.name}
          </div>
          }

          {time &&
          <div style={{display: 'inline-block', marginRight: 15}}>
            <Icon name='time' />
            <TimeAgo date={time} formatter={formatter} />
          </div>
          }

          {doneAt &&
          <div style={{display: 'inline-block'}}>
            <Icon name='check' />
            <TimeAgo date={doneAt} formatter={formatter} />
          </div>
          }
        </Card.Content>
        }
      </Card>
  );
};

function getEditedUploading(pendingEditedWorks, work) {
  let editedUploading = false;
  let editedUploadingWork = false;

  for (let id in pendingEditedWorks) {
    if (pendingEditedWorks[id].id === work.id) {
      editedUploading = pendingEditedWorks[id].uploading;
      editedUploadingWork = true;
    }
  }
  return [editedUploading, editedUploadingWork];
}

